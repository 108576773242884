.btn-secondary {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
  color: var(--dark-grey);
  background-color: transparent;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
  width: 73px;
  height: 16px;
  text-decoration: none;
}

.icon {
  width: 18px;
  height: 13px;

  /* Desktop/Button */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* or 100% */

  /* Dark grey */

  /* color: #656565; */
}

.btn-secondary:hover {
  color: var(--orange);
}
