@font-face {
  font-style: normal;
  src: url("assets/fonts/interregular400.ttf");
  font-family: "Inter";
  font-weight: 400;
}
@font-face {
  font-style: bold;
  src: url("assets/fonts/intermedium500.ttf");
  font-family: "Inter";
  font-weight: 500;
}

:root {
  --white: #f4f6f8;
  --light-grey: #b6b6b6;
  --dark-grey: #656565;
  --black: #1e1f21;
  --orange: #fa501a;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

.app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  overflow-y: hidden;
}

.app-container {
  transition: 0.5s ease-in-out;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 40px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

h1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 82px;
  line-height: 92px;
  letter-spacing: 0.02em;
  /* color: var(--black); */
}

h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 3.375rem;
  line-height: 68px;
  /* identical to box height, or 126% */

  letter-spacing: 0.02em;

  /* black */

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

h3 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 42px;
}

h5 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: var(--light-grey);
}

.subtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

body {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  text-transform: uppercase;
}

@media screen and (min-width: 1200px) {
}

@media screen and (max-width: 480px) {
  .app-container {
    transition: 0.5s ease-in-out;
    /* max-width: 480px; */
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;
    margin: 0 16px;
  }

  h1 {
    font-size: 32px;
    line-height: 42px;
  }

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 42px;
    flex-grow: 0;
    align-self: stretch;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 34px;
    /* identical to box height, or 131% */

    letter-spacing: 0.02em;
  }

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .subtitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
  }

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (min-width: 481px) and (max-width: 960px) {
  .app-container {
    transition: 0.5s ease-in-out;
    /* max-width: 480px; */
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;
    margin: 0 auto;
    padding-left: 5%;
    padding-right: 5%;
  }

  h1 {
    font-size: 52px;
    line-height: 62px;
  }

  h2 {
    font-size: 30px;
    line-height: 42px;
  }
}

@media screen and (min-width: 961px) and (max-width: 1024px) {
  h1 {
    font-size: 55px;
    line-height: 65px;
  }

  h2 {
    font-size: 44px;
    line-height: 54px;
  }

  h3 {
    font-size: 30px;
    line-height: 34px;
    /* identical to box height, or 131% */

    letter-spacing: 0.02em;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1210px) {
  /* .app-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 30px;
    margin: 0 auto;
  } */

  h1 {
    font-size: 62px;
    line-height: 72px;
  }

  h2 {
    font-size: 44px;
    line-height: 54px;
  }

  h3 {
    font-size: 30px;
    line-height: 34px;
    /* identical to box height, or 131% */

    letter-spacing: 0.02em;
  }
}

@media screen and (min-width: 1211px) and (max-width: 1412px) {
  h1 {
    font-size: 70px;
    line-height: 80px;
  }
}
