.works {
  /* background-color: rgb(0, 255, 136); */
  grid-column: 1/13;
  padding: 9% 12%;
}

.works-container {
  margin-top: 80px;
}

.works-left {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 40px;
  margin-bottom: 200px;
}

.wl-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.wl-container img {
  width: 100%;
  height: 100%;
}

.works-right {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 40px;

  margin-bottom: 200px;
}

.wimg-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20%;
}

.wimg-container img {
  width: 100%;
  height: 100%;
}

.wl-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  padding-right: 20%;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.w-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  padding-left: 20%;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.works-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;

  /* width: 329px;
  height: 76px; */

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.tech {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 18px;

  /* width: 329px;
  height: 26px; */

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.w-body {
  flex: none;
  order: 1;
  flex-grow: 0;
}

.w-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* padding-right: 25px; */
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  flex: none;
  order: 2;
  flex-grow: 0;
}

.mob-img {
  display: none;
}

@media screen and (max-width: 960px) {
  .works {
    padding: 0px;
    margin-top: 160px;
    margin-bottom: 56px;
    grid-column: 1/13;
  }

  .works-container {
    margin-top: 40px;
    padding: 0px;
  }

  .works-left {
    display: grid;
    grid-template-columns: 1fr;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 40px;
    margin-bottom: 104px;
  }

  .wl-container {
    display: none;
  }

  .mob-img {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
  }

  .mob-img img {
    width: 100%;
    height: 100%;
  }

  .wl-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    padding-right: 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .works-right {
    display: grid;
    grid-template-columns: 1fr;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 40px;
    /* padding: 0px 0px 0px 130px;
    gap: 148px; */
    margin-bottom: 104px;
  }

  .wimg-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0px;
    /* width: 100%;
    height: 423px; */
  }

  .wimg-container img {
    width: 100%;
    height: 100%;
  }

  .w-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    padding-left: 0px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
}

@media screen and (min-width: 961px) and (max-width: 1296px) {
  /* .works {
    grid-column: 2/12;
  } */

  .wl-content {
    padding-right: 9%;
  }

  .tech {
    gap: 12px;
  }

  .w-content {
    padding-left: 9%;
  }

  .works-left {
    margin-bottom: 150px;
  }

  .works-right {
    margin-bottom: 150px;
  }
}
