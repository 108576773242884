nav {
  grid-column: 1/13;
  display: grid;
}

.nav-container {
  padding: 20px 30px;
  gap: 32px;
  background: rgba(179, 177, 177, 0.406);
  backdrop-filter: blur(0.625rem);
  border-radius: 58px;
  z-index: 999;
  position: fixed;
  display: flex;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  width: 80vw;
}

.brand {
  min-width: 119px;
}

@supports (backdrop-filter: blur(0.625rem)) {
  .nav-container {
    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(0.625rem);
    -webkit-backdrop-filter: blur(2em);
  }
}

.nav-items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 80px;
  width: 100%;
  height: 31px;
  position: relative;
}

.nav-left {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
}

.nav-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 42px;
  height: 31px;
}

.btn-close {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}

.btn-hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: none;
}

.mobile-toggler {
  display: none;
}

.social-links {
  display: none;
}

@media (max-width: 960px) {
  .mobile-toggler {
    display: block;
  }

  .desktop-toggler {
    display: none;
  }

  .btn-hamburger {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
  }

  .btn-close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    width: 30px;
    height: 30px;
  }

  .nav-right {
    display: none;
  }

  .nav-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px 20px;
    gap: 16px;
    width: 90vw;

    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(16px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 32px;

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .nav-container.expanded {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px;
    gap: 180px;
    width: 90vw;
    height: 90vh;

    background: rgba(255, 255, 255, 0.16);
    backdrop-filter: blur(16px);
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 32px;
  }

  .nav-container.expanded .nav-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 40px;
  }

  .nav-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 180px;
  }

  .nav-container.expanded .nav-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 40px;
    height: 90vh;
  }

  .nav-left {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 42px;

    align-self: stretch;
  }

  .nav-container.expanded .nav-right .button-text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 42px;
  }

  .nav-container.expanded .social-links {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 0px 16px;
    gap: 24px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
  }
}
