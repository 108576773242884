.about {
  grid-column: 1/13;
  padding: 9% 12%;
}

.about-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0px;
  gap: 40px;
  margin-top: 80px;
}

.img-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 22%;
}

.img-container img {
  width: 100%;
  height: 100%;
}

.about-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding-left: 20%;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: none;
  order: 1;
  flex-grow: 0;
  /* I added this to replicate design */
  /* padding-right: 25px; */
}

@media screen and (max-width: 960px) {
  .about {
    padding: 0px;
    margin-top: 146px;
    grid-column: 1/13;
  }

  .about-container {
    display: grid;
    grid-template-columns: 1fr;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0px;
    gap: 40px;
    margin-top: 40px;
  }

  .img-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: 48vh;
  }

  .img-container img {
    width: 100%;
    object-fit: cover;
    object-position: 30% 20%;
  }

  .about-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding: 0px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    align-self: stretch;
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 28px;
    flex: none;
    order: 1;
    flex-grow: 0;
    /* I added this to replicate design */
    /* padding-right: 25px; */
  }
}

@media screen and (min-width: 961px) and (max-width: 1296px) {
  /* .about {
    grid-column: 2/12;
    padding: 0px;
  } */

  .about-container {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 0px;
    gap: 40px;
    margin-top: 80px;
  }

  .img-container {
    padding-left: 12%;
    width: 100%;
    height: 100%;
  }

  .img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .about-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding-left: 12%;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
