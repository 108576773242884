/* .switch-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
} */

.switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 31px;
  border-radius: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(233, 233, 234, 1);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 16px;
}

.slider:before {
  position: absolute;
  content: "";
  left: 3.92%;
  right: 43.14%;
  top: 6.45%;
  bottom: 6.45%;
  background: #fa501a;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 16px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.12);
}

input:checked + .slider {
  background-color: rgb(38, 38, 38);
  border-radius: 16px;
}

/* input:focus + .slider {
  box-shadow: 0 0 1px white;
} */

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}
