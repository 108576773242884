.btn-primary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 160px;
  height: 68px;

  /* Dark grey */

  border: 1px solid var(--dark-grey);
  color: var(--dark-grey);
  background-color: transparent;
  border-radius: 84px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

/* .btn-secondary {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px;
  gap: 4px;
  color: var(--dark-grey);
  background-color: transparent;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
} */

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 160px;
  height: 68px;

  /* Dark grey */

  border: 1px solid var(--dark-grey);
  border-radius: 84px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.button-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

.button-text:hover {
  color: var(--orange);
}

.button-container:hover {
  border: 1px solid var(--orange);
  color: var(--orange);
}

.btn-primary:hover {
  border: 1px solid var(--orange);
  color: var(--orange);
}
