.hero {
  height: calc(95vh + 60px);
  padding: 9% 10%;
  /* padding: 120px 147px; */
  /* padding: 0px; */
  align-items: center;
  display: flex;
  justify-content: center;
  grid-column: 1/13;
}

.greet {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.hero-container {
  display: grid;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  z-index: 1;
}

.title-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  z-index: 999;
}

/* .title-container {
  display: grid;
  flex-direction: column;
  flex-wrap: nowrap;
  z-index: 1;
} */

.row1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.row2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0px 0px 0px 452px;
}

.row3 {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 165px;
}

.orange-text {
  color: var(--orange);
}

.sub-container {
  margin-top: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 41px;
  max-width: 652px;
  max-height: 68px;
}

.ellipse {
  position: absolute;
  width: 812px;
  height: 812px;
  left: 1099px;
  top: -272px;
  background: var(--orange);
  filter: blur(624px);
  z-index: 0;
}

.animation-container {
  position: absolute;
  padding: 0px;
  left: -15%;
  right: 0;
  width: 120%;
  margin: auto;
  z-index: 0;
}

@media screen and (max-width: 960px) {
  .hero {
    padding: 0px;
    height: calc(70vh + 60px);
    margin-top: 90px;
  }

  .hero-container {
    display: grid;
    grid-template-columns: 1fr;
    align-items: flex-start;
    padding: 0px;
    gap: 20px;
    margin-bottom: 108px;
  }

  .title-container {
    padding: 0px;
    gap: 4px;
    margin-top: 20px;
  }

  .row2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    flex: none;
    align-self: stretch;
    flex-grow: 0;
  }

  .row3 {
    display: flex;
    align-items: flex-start;
    padding: 0;
  }

  .sub-container {
    margin-top: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    width: 100%;
  }

  .ellipse {
    position: absolute;
    width: 402px;
    height: 402px;
    left: 132px;
    top: -179px;
    background: var(--orange);
    filter: blur(304px);
    z-index: 0;
  }
  .animation-container {
    position: absolute;
    padding: 0px;
    left: -55%;
    right: 0;
    width: 250%;
    margin: auto;
    z-index: 0;
  }
}

@media screen and (min-width: 688px) and (max-width: 960px) {
  .hero {
    margin-top: 200px;
    height: calc(55vh + 60px);
  }
}

@media screen and (min-width: 961px) and (max-width: 1024px) {
  .hero {
    /* padding: 10% 8%;
    max-height: 900px; */
    margin-top: 100px;
    height: calc(70vh + 60px);
    max-height: 800px;
  }

  .row2 {
    padding: 0px 0px 0px 307px;
  }

  .row3 {
    padding: 0px 0px 0px 114px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1210px) {
  .row2 {
    padding: 0px 0px 0px 349px;
  }

  .row3 {
    padding: 0px 0px 0px 137px;
  }
}

@media screen and (min-width: 1211px) and (max-width: 1400px) {
  .hero {
    padding: 9% 10%;
  }

  .row2 {
    padding: 0px 0px 0px 390px;
  }

  .row3 {
    padding: 0px 0px 0px 158px;
  }
}
