.footer {
  grid-column: 1/13;
  padding: 0% 9% 12% 12%;
}

.footer-container {
  margin-top: 80px;
  margin-bottom: 41px;
  display: grid;
  grid-template-columns: 1fr 0.9fr 0.6fr;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 50px;
  isolation: isolate;
  position: relative;
  z-index: 1;
}

.contact {
  display: flex;
  grid-column: 1;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  /* width: 425px; */

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.time {
  display: flex;
  grid-column: 2;
  flex-direction: column;
  align-items: flex-start;

  min-width: 218px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.s-links {
  display: flex;
  grid-column: 3;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 0px 0px;
  gap: 16px;
  grid-column: 3;

  /* width: 196px;
  height: 102px; */

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.rights {
  display: grid;
  grid-template-columns: 1fr 0.9fr 0.6fr;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;
  width: 100%;

  /* width: 1126px;
  height: 24px; */

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  position: relative;
  z-index: 1;
}

.rights-mobile {
  display: none;
}

.ellipse2 {
  position: absolute;
  width: 812px;
  height: 812px;
  left: 774px;
  top: 185.19px;
  background: var(--orange);
  filter: blur(624px);
  z-index: -1;
}

.mobile-t {
  display: none;
}

@media screen and (max-width: 960px) {
  .footer {
    padding: 0px;
    grid-column: 1/13;
  }

  .footer-container {
    margin-top: 40px;
    margin-bottom: 32px;
    display: grid;
    grid-template-columns: 2fr 0fr 1fr;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    column-gap: 10px;
    row-gap: 0px;
    position: relative;
    z-index: 1;
  }

  .contact {
    display: flex;
    grid-column: 1/2;
    grid-row: 1;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    max-width: 287px;

    /* width: 425px; */

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .time {
    display: flex;
    grid-column: 1;
    grid-row: 2;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;

    /* width: 425px; */

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .s-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0px 0px;
    gap: 30px;
    grid-column: 3;
    grid-row: 1/3;

    /* width: 196px;
    height: 102px; */

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
  }

  .rights {
    display: none;
  }

  .rights-mobile {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    margin-bottom: 125px;
  }

  .ellipse2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;

    position: absolute;
    width: 402px;
    height: 402px;
    left: 180px;
    z-index: -1;
    background: var(--orange);
    filter: blur(304px);
  }

  .mobile-t {
    display: flex;
  }

  .desk-t {
    display: none;
  }
}

@media screen and (min-width: 961px) and (max-width: 1381px) {
}
