.skills {
  grid-column: 1/13;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 120px;
  z-index: 1;
}

.s-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
  width: 100%;
}

.skill-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  gap: 8px;
  width: fit-content;
  height: 78px;
  max-width: 1440px;

  /* light grey */

  border: 1px solid var(--light-grey);
  border-radius: 84px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 38px;
  line-height: 48px;
  /* identical to box height, or 136% */

  /* light grey */

  color: var(--light-grey);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.top-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 1440px;
  height: 78px;

  align-self: stretch;

  will-change: transform;
  animation: animation-left 120s infinite linear;
}

@keyframes animation-left {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(-100%);
  }
}

.bottom-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 0px 40px;
  gap: 20px;

  width: 1440px;
  height: 78px;

  align-self: stretch;

  will-change: transform;
  animation: animation-right 120s infinite linear;
}

@keyframes animation-right {
  from {
    transform: translateX(0%);
  }

  to {
    transform: translateX(100%);
  }
}

@media screen and (max-width: 768px) {
  .skills {
    padding: 0px;
  }

  h4 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    /* identical to box height, or 136% */

    /* light grey */

    color: var(--light-grey);

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .s-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 8px;

    /* position: absolute;
    left: -527px;
    top: 739px; */
  }
}
